.conditions-editor .condition-group {
  border-left:  4px solid #419085;
  border-top:   1px solid rgba(34,36,38,.1);
  margin-left:  0;
  margin-right: 0;
}

.conditions-editor .condition-group > .condition-group {
  margin-left: 1rem;
}

.conditions-editor .condition-group > .header {
  background-color: #f9fafb;
  border-color:     rgba(34,36,38,.1);
  padding-right:    .5rem;
}

.conditions-editor .condition-group > .item {
  padding:          0.5rem;
  border-color:     rgba(34,36,38,.1);
}

.conditions-editor .condition-group > .item > * {
  padding:        0.5rem;
  width:          30%;
  vertical-align: top;
}

.conditions-editor .condition-group > .item > *:last-child {
  padding-right:  0;
  width: 10%;
}

.conditions-editor .condition-group > .header .btn,
.conditions-editor .item .btn {
  box-shadow:     0 0 0 1px #419085 inset;
  color:          #419085;
  background:     transparent none;
  font-weight:    400;
  border-radius: .28571429rem;
  text-transform: none;
  text-shadow:    none;
  padding:        0.25rem .5rem;
  cursor:         pointer;
  display:        inline-block;
  outline:        0;
  border:         none;
  vertical-align: baseline;
  margin-left:    0.5rem;
  user-select:    none;
  font-size:      inherit;
  font-family:    inherit;
  line-height:    inherit;
}

.conditions-editor .condition-group > .header .btn:hover,
.conditions-editor .item .btn:hover {
  background: transparent none;
  color:            #419085;
}
