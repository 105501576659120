.message {
  padding: 5px;
  background: whitesmoke;
  border: 1px solid #eae9e9;
  border-radius: 3px;
}

.message:not(:last-child){
  margin-bottom: 1em;
}

.message ol, .message ul {
  -webkit-margin-before: 0em;
  -webkit-padding-start:30px;
}

.message p {
  margin-bottom: 0em;
}

.message__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5em;
}

.message__timestamp {
  font-style: italic;
}
