.ui.form fieldset.field {
  border:   none;
  padding:  0;
}

.ui.form fieldset.field .field {
  margin-bottom: 0px;
}

/* empty text string in semantic selector */
.ui.form .field.clearable .ui.selection.dropdown .menu > .item:first-child .text {
  display: inline-block;
  height: 1rem;
  color: #999999;
}

.capitalize .ui.input>input {
  text-transform: capitalize;
}
