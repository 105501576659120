.ui.form .fixed-component {
  overflow: auto;
}

.ui.form .fixed-component > * {
  float: left;
  margin: 8px 10px;
}

.active-toggle {
    height: 100%;
    display: flex;
    gap: 10px;
}
.active-toggle label {
  font-size: .92em;
  font-weight: 700;
}