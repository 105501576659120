/*
  We use !important here because so very many components
  apply styles via the style attribute.
*/
@media only print {
  .printable-page {
    z-index:    9999      !important;
    position:   absolute  !important;
    top:        0px       !important;
    bottom:     0px       !important;
    left:       0px       !important;
    right:      0px       !important;
    background: white     !important;
    overflow:   visible   !important;
  }

  * {
    overflow:   visible !important;
  }

  .printable-page .printable-hidden {
    display:  none !important;
  }

  .printable-page .printable-full,
  .printable-page .ui.column.grid>[class*="wide"].column.printable-full,
  .printable-page .ui.grid>.column.row>[class*="wide"].column.printable-full,
  .printable-page .ui.grid>.row>[class*="wide"].column.printable-full,
  .printable-page .ui.grid>[class*="wide"].column.printable-full {
    width: 100% !important;
    left: 0px   !important;
    right: 0px  !important;
  }

  .printable-page .ui.segment {
    border:     0px;
    box-shadow: none;
  }

  .printable-page .ui.form input:not([type]),
  .printable-page .ui.form input[type=date],
  .printable-page .ui.form input[type=datetime-local],
  .printable-page .ui.form input[type=email],
  .printable-page .ui.form input[type=file],
  .printable-page .ui.form input[type=number],
  .printable-page .ui.form input[type=password],
  .printable-page .ui.form input[type=search],
  .printable-page .ui.form input[type=tel],
  .printable-page .ui.form input[type=text],
  .printable-page .ui.form input[type=time],
  .printable-page .ui.form input[type=url],
  .printable-page .ui.selection.dropdown {
    border:       0px;
    padding:      0px;
    line-height:  1rem;
    font-family:  monospace;
  }

  .printable-page .ui.checkbox,
  .printable-page .ui.checkbox input,
  .printable-page .ui.checkbox label::before,
  .printable-page .ui.checkbox label::after,
  .printable-page form button[type=submit],
  .printable-page form .field small,
  .printable-page .ui.selection.dropdown .dropdown.icon {
    display: none;
  }

  .printable-page .ui.checkbox.checked {
    display: inherit;
  }

  .printable-page .ui.checkbox.checked label {
    padding:      0;
    font-family:  monospace;
  }

  .printable-page .ui.form fieldset.field {
    border-bottom: 1px solid #cccccc;
  }

  .printable-page .field .ui.horizontal.list .item {
    padding:  0;
    margin:   0;
  }
}
