.main-component.padded-content {
/*  padding: 1rem 1.5rem;
*/}

.main-component.stage-content {
  background-color: #fdfdfd;
}

/**** Remove input type="number" arrows ****/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/**** /Remove input type="number" arrows ****/
