.sortable-table {
  width: fit-content !important;
  flex: 1;
  display: grid;
}

.sortable-table thead,
.sortable-table tbody,
.sortable-table tr {
  display: contents;
}

.sortable-table th,
.sortable-table td {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sortable-table th {
  position: relative
}

.sortable-table .resize-handle {
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 5px;
  opacity: 0;
  width: 4px;
  cursor: col-resize;
  padding: 0 5px;
}

span.resize-handle:before, 
span.resize-handle:after {
    width: 1px;
    display: inline-block;
    background: black;
    content: " ";
    overflow: hidden;
    height: 100%;
    margin: 1px;
}

.sortable-table .resize-handle:hover,
.header--being-resized .resize-handle {
  opacity: 0.5;
}

.sortable-table th:hover .resize-handle {
  opacity: 0.3;
}

.sortable-table .header-cell-direction-icon {
  display:        inline-block;
  vertical-align: top;
}

.vh-25 {
  height: 10vh;
}
