.fieldNameStyle {
  font-size: 1.6rem;
  font-weight: bold;
  margin: 0 0 30px;
}

.labelStyle {
  margin: 2rem 0 .3rem 0;
  font-size: .93em;
  font-weight: 700;
}