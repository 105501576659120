.ui.form .field.attachment-field label {
  padding-left: 1px;
  display:      inline-block;
  cursor:       pointer;
}

.ui.form .field.attachment-field label .preview {
  position:     relative;
}

.ui.form .field.attachment-field label .preview .overlay {
  transition:       opacity 0.5s;
  position:         absolute;
  bottom:           4px;
  top:              0px;
  left:             0px;
  right:            0px;
  background-color: rgba(0,0,0, 0.3);
  color:            rgba(255,255,255,0.8);
  font-size:        1.5rem;
  opacity:          0;
}

.ui.form .field.attachment-field label .preview .overlay:after {
  content:          'Edit';
  position:         absolute;
  top:              50%;
  left:             50%;
  margin-right:     -50%;
  transform: translate(-50%, -50%);
}

.ui.form .field.attachment-field label .preview .overlay:hover {
  opacity:          1;
}

.ui.form .field.attachment-field .attachment-control-group {
  padding: 0.375em 0.5em;
  border: 1px solid rgba(34,36,38,0.15);
  border-radius: 0.275rem;
}
.ui.form .field.attachment-field .attachment-control-group button {
  margin-right: 1em;
}
.ui.form .field.attachment-field .attachment-control-group i {
  cursor: pointer;
  margin-left: 0.8em;
}
