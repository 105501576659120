.condition__control .btn__label {
	margin-left: 0.25em;
	font-weight: normal;
	cursor: pointer;
}

.condition__control .ui.table {
	margin: 0;
}

.condition__control .ui.table td {
	padding-top: 0;
	padding-bottom: 0;
}

.condition__empty {
	margin: 0.5em 0;
}
