dl.data-list {
  display: grid;
  grid-template-columns: 100fr 100%;
}

dl.data-list dt {
  justify-self: end;
  white-space:  nowrap;
  font-weight:  bold;
}

dl.data-list dt::after {
  content:      ':';
  font-weight:  bold;
}

dl.data-list dd {
  margin-inline-start: 2rem;
}
