a {
  color: #419085;
}

a:hover {
  color: #217065;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.ui.basic.primary.button,
.ui.basic.blue.button {
  color: #419085 !important;
  box-shadow: 0 0 0 1px #419085 inset !important;
}

.ui.basic.primary.button:focus,
.ui.basic.primary.button:hover,
.ui.basic.primary.button:active,
.ui.basic.blue.button:focus,
.ui.basic.blue.button:hover,
.ui.basic.blue.button:active {
  color: #217065 !important;
  box-shadow: 0 0 0 1px #217065 inset !important;
}

.ui.primary.button {
  background-color: #419085;
}

.ui.primary.button:hover,
.ui.primary.button:active,
.ui.primary.button:focus {
  background-color: #217065;
}

.ui.toggle.checkbox input:focus:checked~.box:before, .ui.toggle.checkbox input:focus:checked~label:before,
.ui.toggle.checkbox input:checked~.box:before, .ui.toggle.checkbox input:checked~label:before {
  background-color: #419085 !important;
}

.ui.left.sidebar {
  position: absolute;
  right:    0px;
}

.ui.basic.label {
  background-color: transparent !important;
}

.ui.basic.blue.label {
  color: #419085 !important;
  border-color: #419085 !important;
}

.ui.blue.label {
  background-color: #419085 !important;
}

.ui.info.message,
.ui.warning.message,
.ui.success.message,
.ui.error.message {
  background: transparent;
}

.ui.info.message {
  color: #419085;
}

.ui.icon.message>.icon:not(.close) {
  font-size: 2rem;
}

.ui.message.basic {
  border:     0px;
  box-shadow: none;
  padding:    0px;
  margin:     0px;
}

.ui.celled.list>.item,
.ui.celled.list>.item:first-child,
.ui.celled.list>.item:last-child {
  padding: 0.5rem;
}

.ui.grid.collapsed {
  display: inline-flex;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  pointer-events: none;
  opacity: 0.75;
}
