.ui.dropdown .menu {
  z-index: 1001 !important;
}

.report-table {
  position: absolute;
  top:      0;
  bottom:   0;
  left:     0;
  right:    0;
}

.report-table .table {
    border-top: 0;
}

.report-table .table thead tr th {
    position: -webkit-sticky !important;
    position: sticky !important;
    top: 0;
    z-index: 2;
    border-top: 1px solid rgba(34,36,38,.15);
}
