.selection-panel {
  border-radius:  4px;
  border:         1px solid rgba(0, 0, 0, 0.1);
  box-shadow:     0px 1px 2px rgba(0, 0, 0, 0.2);
  padding:        0;
}

.selection-panel .item {
  display:        block;
  padding:        1rem;
  border-bottom:  1px solid rgba(0, 0, 0, 0.1);
  font-size:      1.4rem;
}

.selection-panel .item:last-child {
  border-bottom:  0px;
}

.selection-panel .item::before {
  content:        "\f058";
  color:          transparent;
  font-family:    outline-icons;
  margin-right:   1rem;
  line-height:    1;
  font-size:      2rem;
  vertical-align: middle;
}

.selection-panel .item.active::before {
  color:          inherit;
}
