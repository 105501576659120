.leaflet-marker-icon.marker-group-1 {
  filter: hue-rotate(135deg);
}

.leaflet-marker-icon.marker-group-2 {
  filter: hue-rotate(275deg);
}

.leaflet-marker-icon.marker-group-3 {
  filter: hue-rotate(45deg);
}

.leaflet-marker-icon.marker-group-4 {
  filter: hue-rotate(187deg);
}

.leaflet-marker-icon.marker-group-5 {
  filter: hue-rotate(315deg);
}

.leaflet-marker-icon.marker-group-6 {
  filter: hue-rotate(160deg);
}

.leaflet-marker-icon.marker-group-7 {
  filter: hue-rotate(270deg);
}

.leaflet-marker-icon.marker-group-8 {
  filter: hue-rotate(90deg);
}
