.master-detail-component .record-list-component .record-list-item-component {
  padding: 1em 1.5em !important;
}

.master-detail-component .record-list-component .record-list-item-component.active {
  background-color: #419085;
}

.master-detail-component .record-list-component .record-list-item-component.active * {
  color: white;
}

.master-detail-component .record-list-component .record-list-item-component:last-child {
  border-bottom: 1px solid rgba(34,36,38,.15);
}
.master-detail-component .record-list-scroller {
  overflow-y: scroll;
  flex: 1;
  padding-bottom: 2rem;
}

.master-detail-component .record-list-container {
  display: flex !important;
  flex-direction: column;
}

.master-detail-component .record-list-container .input input {
  border-radius:  0;
  border-top:     none;
}

.master-detail-component .record-list-container .record-list-message {
  padding: 2em;
  color: #828282;
  text-align: center;
}

.master-detail-component .record-list-filters {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #d3d2d2;
  padding: 8px 10px 10px;
}

.master-detail-component .record-list-container .record-list-category .dropdown {
  border-radius: 0;
}

.master-detail-component .record-list-container .record-list-category .dropdown .menu {
  padding-left: 20px;
  padding-right: 20px;
}

.master-detail-component .record-list-container .record-list-category .dropdown .icon {
  float: left;
  margin-right: 9px;
  margin-left: -3px;
}
