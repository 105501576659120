.permission-table .ui.checkbox.disabled .box:before,
.permission-table .ui.checkbox.disabled label:before {
  background: transparent;
  border-color: transparent;
}

.permission-table .header-cell .ui.checkbox.disabled .box:after,
.permission-table .header-cell .ui.checkbox.disabled label:after {
  display: none;
}

.permission-table .header-cell .ui.checkbox.disabled label {
  padding-left: 0px;
}
