.tool-bar-component.padded-content {
  padding: 1rem 1rem;
}

.tool-bar-component.section-menu {
  background-color: #efefef;
  border-top:     1px solid #dddddd;
  border-bottom:  1px solid #dddddd;
}
.tool-bar-component.section-menu.padded-content {
  padding: .5rem 1rem;
}
