.search-results .result {
  padding: 1em 1.5em !important;
}

.search-results .result.active {
  background-color: #419085;
}

.search-results .result.active * {
  color: white !important;
}
