.conditions-viz {
  font-family:  'monospace';
  font-size:    1rem;
}

.conditions-viz .conditional-comparison-operand,
.conditions-viz .conditional-comparison-operator {
  display: inline-block;
}

.conditions-viz .conditional-logical-operator,
.conditions-viz .conditional-comparison-operand,
.conditions-viz .conditional-comparison-operator {
  padding:  .25rem 1rem;
}

.conditions-viz .conditional-logical-expression {
  border:       1px solid rgba(34,36,38,.1);
  border-left:  4px solid #419085;
}

.conditions-viz .conditional-logical-expression .conditional-logical-expression {
  margin-left:    1rem;
  border-right:   0;
}

.conditions-viz .conditional-logical-operator,
.conditions-viz .conditional-comparison-operator {
  font-weight:  bold;
}

.conditions-viz .conditional-comparison-expression {
  background-color: #f9fafb;
}

.conditions-viz .conditional-comparison-operator {
  padding: .25rem 0;
}

.conditions-viz .conditional-logical-operator {
  text-transform: uppercase;
}
