.recipient__control .ui.search {
  margin-left: 0;
}

.recipient__list {
  padding: 0.5em 0 0 0.5em;
}

.recipient__list p {
  margin-bottom: 0.5em;
}

.ui.form .recipient__list .field {
  margin-bottom: 0.33em;
}
