.master-detail-component > .master-pane {
  border-right: 1px solid #f3f3f3;
  background-color: #f9f9f9;
}

.master-detail-component > .master-pane .tool-bar-component {
  border-right:  1px solid #fdfdfd;
  font-size:     20;
}

.master-detail-component .tool-bar-component .button.circular.small {
  margin-top: -0.15rem;
}

.master-detail-component > .detail-pane {
  padding: 1em 1.5em;
}

.sidecard img {
	width: 100%;
	border-radius: 3px;
}

.sidecard ul, .sidecard ol {
	padding-left: 1em;
	margin-top: -10px;
}


.master-detail-component .ui.button.toggle-sidebar,
.master-detail-component .ui.button.toggle-sidebar:hover,
.master-detail-component .ui.button.toggle-sidebar:focus {
    box-shadow: none !important;
    padding-left: 0px;
}
.master-detail-component.right-sidebar-closed .ui.button.toggle-sidebar-right >.icon {
  transform: rotate(-180deg);
}
.master-detail-component .ui.button.toggle-sidebar-right >.icon {
  transition: transform 0.2s;
}

.master-detail-component.left-sidebar-closed > .sidebar-component {
    width: 0 !important;
}

.master-detail-component.left-sidebar-closed > .sidebar-component .section-menu {
    padding: 6px 4px;
}

.master-detail-component.left-sidebar-closed > .sidebar-component .record-list-scroller {
    opacity: 0;
}

.master-detail-component.left-sidebar-closed > .main-component {
    inset: 0px 0px 0px 0 !important;
}

.master-detail-component > .sidebar-component { transition: width 0.5s; }
.master-detail-component > .sidebar-component .section-menu { transition: padding 0.5s; }
.master-detail-component > .sidebar-component .record-list-scroller { transition: opacity 0.2s; }
.master-detail-component > .main-component { transition: inset 0.5s; }


.master-detail-component.right-sidebar-closed .detail-pane-main {
  width: 100% !important;
}

.master-detail-component.right-sidebar-closed .sidecard {
  width: 0% !important;
  left: 100% !important;
  display: none;
}

.master-detail-component .detail-pane-main { transition: width 0.5s; }
.master-detail-component .sidecard { transition: display 0.5s; }
