.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.positioned {
  position: relative;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
