.text-muted {
  color: #999999;
}

.text-negative {
  color: #FF0000;
}

.text-uppercase {
  text-transform: uppercase;
}
